'use client';

import React, { useEffect } from 'react';

import font from '@/helpers/utils/fonts';
import TextLink from '@/components/atoms/TextLink/TextLink';
import HeadingOne from '@/components/atoms/HeadingOne/HeadingOne';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import CTA from '@/components/atoms/CTA/CTA';
import PUBLICATION from '@/helpers/utils/publication';

// Error components must be Client Components

export default function Error({ error, reset }: { reset: () => void; error: Error }) {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('error', 'true');
    if (!window.location.search.includes('error=true')) {
      // @ts-ignore-line typescript is not supported
      window.location.search = urlParams;
    }
  }, [error]);

  return (
    <div className={font(PUBLICATION)}>
      <HeadingOne publication={PUBLICATION}>Something went wrong in the article page!</HeadingOne>
      <Paragraph>
        Please return to <TextLink href="/">the homepage</TextLink>. or
      </Paragraph>
      <p>
        <CTA
          design="primary"
          label="Try again"
          ariaLabel="Try again"
          onClick={
            // Attempt to recover by trying to re-render the segment
            () => reset()
          }
        />
      </p>
    </div>
  );
}
