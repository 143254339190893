import { Social } from '@/ts/types/socials';

function getCapitalizedSocialName(social: Social): string {
  switch (social) {
    case 'tiktok':
      return 'TikTok';
    case 'whatsapp':
      return 'WhatsApp';
    default:
      return social.charAt(0).toUpperCase() + social.slice(1);
  }
}

export default getCapitalizedSocialName;
